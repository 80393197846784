import { Component } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import Axios from "axios";
import { BASE_URL_MANAGER } from "@/config";
import { store } from "@/app/store";
// Components
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import NavBar from "@/app/components/shared/nav-bar/NavBar.vue";

@Component({
  name: "admins-management",
  components: { AdminNavbar, NavBar },
})
export default class AdminsManagement extends VueWizard {
  public admins: any = [];
  public user: any;
  created(): void {
    this.user = store.state.user;
  }
  /**
   * metodo que se ejecuta al inciar el modulo
   */
  mounted(): void {
    this.list();
  }
  /**
   * Listo las companies
   */
  list(): void {
    let url = `${BASE_URL_MANAGER}/user/all/${this.user.user.company._id}`;

    Axios.get(url, {
      params: {
        role: "ADMINS",
      },
    })
      .then((res: any) => {
        this.admins = res.data.users.filter((val: any) => {
          return val.role == "ADMIN";
        });
      })
      .catch((err) => {
        throw err;
      });
  }
}
